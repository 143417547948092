import React from 'react';
import { Text } from '../../components/UI/Text';
import { RepairDetailData } from '../../types/order/order.model';
import { messages } from '../../Messages';

interface Props {
  orderData: RepairDetailData;
  brandDetails: any;
}

export const OrderSummaryTotalInfo: React.FC<Props> = ({ orderData, brandDetails }) => {
  return (
    <>
      <div className="total">
        <Text
          text={messages.repairBookingForm.summary.total}
          className={`${brandDetails.brandName}-description-bold small-screen-font-size`}
        />
        <Text
          text={`${orderData.currencyCode} ${orderData.amountPaid.toFixed(2)}`}
          className={`${brandDetails.brandName}-description-bold small-screen-font-size`}
        />
      </div>
      <Text
        text="*inclusive of all taxes"
        className={`${brandDetails.brandName}-description-small tax-description small-screen-font-size`}
      />
    </>
  );
};
