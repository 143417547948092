import { messages } from '../../Messages';
import { Text } from '../../components/UI/Text';
import { RepairDetailData } from '../../types/order/order.model';

interface Props {
  orderData: RepairDetailData;
  underWarranty: boolean;
  brandDetails: any;
}

export const CheckoutShippingInfo: React.FC<Props> = ({ orderData, underWarranty, brandDetails }) => {
  return (
    <>
      {orderData.serviceType === 'warranty' && underWarranty && !orderData.receiptAvailable ? (
        <>
          <div className="cost">
            <Text text={`${messages.common.shippingCost}`} className={`${brandDetails.brandName}-description bold`} />
            <Text text={`${orderData.currencyCode} ${0}`} className={`${brandDetails.brandName}-description bold`} />
          </div>
          <div className="cost">
            <Text text="Subtotal" className={`${brandDetails.brandName}-description bold`} />
            <Text
              text={`${orderData.currencyCode} ${(orderData.totalCost - parseFloat(orderData.shippingCost) - parseFloat(orderData.returnShippingCost)).toFixed(2)}`}
              className={`${brandDetails.brandName}-description bold`}
            />
          </div>
        </>
      ) : (
        <>
          <div className="cost">
            <Text text={`${messages.common.shippingCost}`} className={`${brandDetails.brandName}-description bold`} />
            <Text
              text={`${orderData.currencyCode} ${orderData.shippingCost}`}
              className={`${brandDetails.brandName}-description bold`}
            />
          </div>
          <div className="cost">
            <Text
              text={`${messages.common.returnShippingCost}`}
              className={`${brandDetails.brandName}-description bold`}
            />
            <Text
              text={`${orderData.currencyCode} ${orderData.returnShippingCost}`}
              className={`${brandDetails.brandName}-description bold`}
            />
          </div>
          <div className="cost">
            <Text text="Subtotal" className={`${brandDetails.brandName}-description bold`} />
            <Text
              text={`${orderData.currencyCode} ${orderData.totalCost?.toFixed(2)}`}
              className={`${brandDetails.brandName}-description bold`}
            />
          </div>
        </>
      )}
    </>
  );
};
