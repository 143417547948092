import React from 'react';
import './home.scss';
import { Button } from '../../components/UI/Button';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../components/UI/Text';
import { BrandContext } from '../../App';
import { ApiKeyContext, BrandConfigurationContext } from '../..';
import { getBlobsInContainer } from '../../blobConfig';
import { TextField } from '@mui/material';
import { getLineItemsByOrderID } from '../../utils/api';
import { MountedModal } from '../../components/UI/Modal';
import { PageLoader } from '../../components/UI/PageLoader';

const Logo = React.lazy(() => import('../../components/UI/Logo/index'));

export const DynamicHome: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [orderID, setOrderID] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [lineItems, setLineItems] = React.useState({});
  const [selected, setSelected] = React.useState(0);

  const [logoSource, setLogoSource] = React.useState('');
  const onRepairClick = () => {
    navigate('/repair-booking');
  };
  const brandDetails = React.useContext(BrandContext);
  const brandConfiguration = React.useContext(BrandConfigurationContext);

  let apikey = React.useContext(ApiKeyContext);
  // let apikey = 'd60aa6f84c393ef4ea99c1b7de41c0d799344e79d9ad2267d7e37282ce8379a8';

  const handleOrderIDClick = React.useCallback(async () => {
    if (orderID !== 0) {
      setLoading(true);
      await getLineItemsByOrderID(apikey, orderID).then((resp) => {
        setLineItems(resp);
        setOpenModal(true);
      });
    } else {
      console.error('Order ID is empty or not a valid number');
      // setLoading(false);
    }
  }, [orderID]);

  const getBrandLogo = React.useCallback(async (brandName: string) => {
    await getBlobsInContainer(apikey, brandName, 'logo.svg')
      .then((resp) => {
        setLoading(true);
        setLogoSource(resp);
      })
      .catch((error) => {
        console.log("Couldn't Fetch Logo");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    // setLoading(true);
    getBrandLogo(brandDetails?.brandName);
    // setLoading(false);
  }, [brandDetails]);

  const handleOrderIDInputChange = (event: any) => {
    const value = parseInt(event.target.value, 10) || 0;
    setOrderID(value);
  };

  return (
    <div className="dynamic-home-page">
      {brandConfiguration && brandDetails && (
        <>
          {orderID !== 0 && openModal && (
            <MountedModal
              open={openModal}
              handleClose={(isSelectionMade) => {
                setOpenModal(false);
                if (!isSelectionMade) {
                  setTimeout(() => {
                    setLoading(false);
                  }, 500);
                }
              }}
              orderID={orderID}
              lineItems={lineItems}
              setSelected={setSelected}
              selected={selected}
              setLoading={setLoading}
            />
          )}
          <div className="AWAN-background-image-container"></div>
          <Logo imageSource={logoSource} altText="image" />
          <div className="content">
            <Text
              text={brandConfiguration.homePageHeader}
              className={`${brandDetails.brandName}-heading heading-dynamic`}
            />
            <div className="dynamic-home-description">
              <Text
                text={brandConfiguration.homePageDescription}
                className={`${brandDetails.brandName}-description description-dynamic`}
              />
            </div>

            <div className="register-repair">
              <Text text="Register your repair" className={`${brandDetails?.brandName}-heading`} />
              <Text
                text="Enter order id provided on the bill"
                className={`${brandDetails?.brandName}-description ${brandDetails?.brandName}-bold`}
              />
              <div className="order-id-input">
                <TextField
                  placeholder="121212121212"
                  name="orderID"
                  value={orderID !== 0 ? orderID.toString() : ''}
                  onChange={(event) => handleOrderIDInputChange(event)}
                  size="small"
                  className="textfield"
                />
                <Button
                  buttonText="Submit"
                  className="submit-button"
                  onClick={handleOrderIDClick}
                  disabled={!brandDetails}
                />
              </div>
            </div>
            <div className="separator">
              <hr />
              <Text text="OR" className={`${brandDetails?.brandName}-description shorter-line-height`} />
              <hr />
            </div>
            <div className="manual-flow">
              <Text text="Enter Details Manually" className={`${brandDetails?.brandName}-description-bold`} />
              <Button
                variant="contained"
                className={`${brandDetails.brandName}-font enter-button`}
                buttonText="Enter"
                onClick={onRepairClick}
                disabled={!brandDetails}
              />
            </div>
            <div className="footer-text">
              <Text
                text="Service charge is to be paid by you."
                className={`${brandDetails?.brandName}-description-small italics`}
              />
            </div>
          </div>

          {/* Responsive */}
          <div className="responsive-home-page">
            <div className="content-responsive">
              <Text text="Register your repair" className={`${brandDetails?.brandName}-sub-heading`} />
              <div className="repair-method-selection">
                <div className="register-repair">
                  <Text
                    text="Enter order id provided on the bill"
                    className={`${brandDetails?.brandName}-description ${brandDetails?.brandName}-bold`}
                  />
                  <div className="order-id-input">
                    <TextField
                      placeholder="121212121212"
                      name="orderID"
                      value={orderID !== 0 ? orderID.toString() : ''}
                      onChange={(event) => handleOrderIDInputChange(event)}
                      size="small"
                      className="textfield"
                    />
                    <Button
                      buttonText="Submit"
                      className="submit-button"
                      onClick={handleOrderIDClick}
                      disabled={!brandDetails}
                    />
                  </div>
                </div>
                <div className="separator">
                  <hr />
                  <Text text="OR" className={`${brandDetails?.brandName}-description`} />
                  <hr />
                </div>
                <div className="manual-flow">
                  <Text text="Enter Details Manually" className={`${brandDetails?.brandName}-description-bold`} />
                  <Button
                    variant="contained"
                    className={`${brandDetails.brandName}-font enter-button`}
                    buttonText="Enter"
                    onClick={onRepairClick}
                    disabled={!brandDetails}
                  />
                </div>
              </div>
              <Text text={brandConfiguration.homePageHeader} className={`${brandDetails.brandName}-sub-heading`} />
              <div className="dynamic-home-description">
                <Text
                  text={brandConfiguration.homePageDescription}
                  className={`${brandDetails.brandName}-description description-dynamic`}
                />
              </div>
              <div className="footer-text">
                <Text
                  text="Service charge is to be paid by you."
                  className={`${brandDetails?.brandName}-description-small italics`}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {loading && <PageLoader className="overlay" />}
    </div>
  );
};
