import { FetchData } from './components/FetchData';
import { OrderSummary } from './pages/order-summary';
import { RepairBooking } from './pages/repair-booking';
import { Checkout } from './pages/checkout';
import { Error } from './pages/error';
import { SelectHome } from './pages/select-home/select-home';
import DryCleanBooking from './pages/dryclean-booking/DryCleanBooking';
import { DryCleanCheckout } from './pages/dryclean-booking/DryCleanCheckout';
import { DryCleanOrderSummary } from './pages/dryclean-booking/DryCleanOrderSummary';
import LanguageSelect from './components/UI/SingleSelect/LanguageSelect';
import Layout from './pages/dryclean-booking/Layout';

const AppRoutes = [
  {
    path: '/',
    element: (
      <>
        <LanguageSelect />
        <SelectHome />
      </>
    ),
  },
  {
    path: '/repair-booking',
    element: (
      <>
        <LanguageSelect />
        <RepairBooking />
      </>
    ),
  },
  {
    path: '/dryclean-booking',
    element: (
      <Layout>
        <DryCleanBooking />
      </Layout>
    ),
  },
  {
    path: '/dryclean-booking/checkout',
    element: (
      <Layout>
        <DryCleanCheckout />
      </Layout>
    ),
  },
  {
    path: '/dryclean-booking/order-summary',
    element: (
      <Layout>
        <DryCleanOrderSummary />
      </Layout>
    ),
  },
  {
    path: '/order-summary',
    element: (
      <>
        <LanguageSelect />
        <OrderSummary />
      </>
    ),
  },
  {
    path: '/fetch-data',
    element: (
      <>
        <LanguageSelect />
        <FetchData />
      </>
    ),
  },
  {
    path: '/checkout',
    element: (
      <>
        <LanguageSelect />
        <Checkout />
      </>
    ),
  },
  {
    path: '/error',
    element: <Error />,
  },
];

export default AppRoutes;
