import React from 'react';
import { Text } from '../../components/UI/Text';
import { RepairDetailData } from '../../types/order/order.model';
import { messages } from '../../Messages';

interface Props {
  orderData: RepairDetailData;
  brandDetails: any;
  underWarranty: boolean;
}

export const OrderSummaryShippingInfo: React.FC<Props> = ({ orderData, brandDetails, underWarranty }) => {
  return (
    <div className="shipping">
      <Text
        text={messages.repairBookingForm.summary.shippingBack}
        className={`${brandDetails.brandName}-description-small small-screen-font-size`}
      />
      {orderData.serviceType === 'warranty' && underWarranty && !orderData.receiptAvailable ? (
        <Text
          text={`${orderData.currencyCode} ${0}`}
          className={`${brandDetails.brandName}-description-small small-screen-font-size`}
        />
      ) : (
        <Text
          text={`${orderData.currencyCode} ${(
            parseFloat(orderData.shippingCost) + parseFloat(orderData.returnShippingCost)
          ).toFixed(2)}`}
          className={`${brandDetails.brandName}-description-small small-screen-font-size`}
        />
      )}
    </div>
  );
};
