import React from 'react';
import { Text } from '../../components/UI/Text';
import { RepairDetailData } from '../../types/order/order.model';
import { messages } from '../../Messages';

interface Props {
  orderData: RepairDetailData;
  brandDetails: any;
}

export const OrderSummaryRepairInfo: React.FC<Props> = ({ orderData, brandDetails }) => {
  return (
    <>
      <Text
        text={messages.repairBookingForm.summary.repair}
        className={`${brandDetails.brandName}-description-bold small-screen-font-size`}
      />
      {orderData.damageDetails?.map((damage: any, index: number) => (
        <div className="damage-container" key={index}>
          <div className="damage-title">
            <Text
              text={`${index + 1}.`}
              className={`${brandDetails.brandName}-description-small text small-screen-font-size`}
            />
            <Text
              text={`${damage.damageSide} side - `}
              className={`${brandDetails.brandName}-description-small bold text small-screen-font-size`}
            />
            <Text
              text={damage.damageType}
              className={`${brandDetails.brandName}-description-small text small-screen-font-size`}
            />
            {orderData.serviceType === 'warranty' && damage.isUnderWarranty && !orderData.receiptAvailable ? (
              <Text
                text={messages.repairBookingForm.summary.underWarranty}
                className={`${brandDetails.brandName}-description-small text light-text small-screen-font-size`}
              />
            ) : (
              <></>
            )}
          </div>
          <div className="damage-amount">
            <Text
              text={`${damage.currencyCode} ${(orderData.serviceType === 'warranty' && damage.isUnderWarranty && !orderData.receiptAvailable)?0:damage.amount}`}
              className={`${brandDetails.brandName}-description-small small-screen-font-size`}
            />
          </div>
        </div>
      ))}
    </>
  );
};
