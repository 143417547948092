import { FormDataType, OrderStatus } from './constants';
import { PaymentStatusEnum } from '../../types/enum/order.enum';
import { BrandConfigurationModel } from '../../types/brand/brand.model';
import { DamageOptionModelNew, DamageRespModel, SubDamageOptionModelNew } from '../../types/damages/damageOption.model';

export const formatFormArgs = (data: any) => {

  const logisticsData = JSON.parse(data.logisticsData);
  const deliverylogisticsData = JSON.parse(data.deliverylogisticsData);

  // console.log("oasrsed adata.. pickupdetails: ",logisticsData);
  // console.log("oasrsed adata.. returnDetails: ",deliverylogisticsData);


  return {
    serviceType: data.serviceType,
    receiptAvailable: data.receiptAvailable,
    returnProduct: data.returnProduct,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phoneNumber: data.phoneNumber,
    address: `${data.streetName},${data.streetNumber}`,
    postalCode: data.postalCode,
    city: data.city,
    country: data.country,
    modelName: data.modelName,
    modelNumber: data.modelNumber,
    productionCode: data.productionCode,
    isLabelsMissing: data.isLabelsMissing,
    productCategory: data.productCategory,
    totalCost: data.totalCost,
    currencyCode: data.damageDetails?.[0].currencyCode,
    comments: data.comments,
    damageDetails: data.damageDetails,
    status: OrderStatus.DRAFT,
    paymentStatus: PaymentStatusEnum.PENDING,
    amountPaid: data.amountPaid,
    repairerId: data.repairerId,
    shippingCost: data.shippingCost,
    returnShippingCost: data.returnShippingCost,
    product:data.productCategory,
    category:data.category??"Clothes",
    competence:data.productSubCategory,
    // logisticsData: data.logisticsData,
    discountCode: data.discountCode,
    integrationID: data.integrationID,
    // "shipmentDetails": {
    //   "provider": "string",
    //   "pickupDetail": {
    //     "productName": "string",
    //     "productCode": "string",
    //     "customerID": "string",
    //     "name": "string",
    //     "address": "string",
    //     "city": "string",
    //     "country": "string"
    //   },
    //   "returnDetail": {
    //     "productName": "string",
    //     "productCode": "string",
    //     "customerID": "string",
    //     "name": "string",
    //     "address": "string",
    //     "city": "string",
    //     "country": "string"
    //   }
    // },
    shipmentDetails:{
      provider:"postnord",
      pickupDetail:{
        productName: logisticsData.product.name,
        productCode: logisticsData.product.code,
        customerID: logisticsData.pickupPoint?.customerId ?? "",
        name:logisticsData.pickupPoint?.name ?? "",
        addressLine1:logisticsData.pickupPoint?.physicalAddress?.street ?? "",
        addressLine2:logisticsData.pickupPoint?.physicalAddress?.street2 ?? "",
        postalCode:logisticsData.pickupPoint?.physicalAddress?.postalCode ??"",
        city:logisticsData.pickupPoint?.physicalAddress?.city ?? "",
        countryCode:logisticsData.pickupPoint?.physicalAddress?.countryCode ?? ""
      },
      returnDetail:{
        productName: deliverylogisticsData.product.name,
        productCode: deliverylogisticsData.product.code,
        customerId: deliverylogisticsData?.pickupPoint?.customerId ?? "",
        name:deliverylogisticsData.pickupPoint?.name ?? "",
        addressLine1:deliverylogisticsData.pickupPoint?.physicalAddress?.street ?? "",
        addressLine2:deliverylogisticsData.pickupPoint?.physicalAddress?.street2 ?? "",
        postalCode:deliverylogisticsData.pickupPoint?.physicalAddress?.postalCode ?? "",
        city:deliverylogisticsData.pickupPoint?.physicalAddress?.city ?? "",
        countryCode:deliverylogisticsData.pickupPoint?.physicalAddress?.countryCode ?? ""
      }
    },
    // postnordProduct:  JSON.stringify(logisticsData.product),
    // postnordPickupPoint: JSON.stringify(logisticsData.pickupPoint),
    // postnordReturnProduct: JSON.stringify(deliverylogisticsData.product),
    // postnordReturnPickupPoint: JSON.stringify(deliverylogisticsData.pickupPoint),
    
    // postnord
  };
};

export const calculateCost = (getValues: any, underWarranty: boolean) => {
  let totalCost = 0;
  let amountPaid = 0;
  getValues('damageDetails').map((damage: any, index: number) => {
    totalCost += damage.amount;
    if (
      !getValues(`damageDetails[${index}].isUnderWarranty`) ||
      getValues('serviceType') === 'repair' ||
      getValues('receiptAvailable')
    ) {
      // console.log("pehla iff me")
      amountPaid += damage.amount;
    }
  });
  if (!underWarranty) {
    // console.log("dusre if me..")
    amountPaid = totalCost;
  }
  return { totalCost, amountPaid };
};

export const getFormStepsNames = (serviceType: string, brandConfig: BrandConfigurationModel) => {
  let formStepsList: Array<string> = [];
  if (serviceType == 'warranty') {
    brandConfig.warrantyFormSteps.map((warrantytFormStep) => {
      formStepsList[Number(warrantytFormStep.stepIndex)] = warrantytFormStep.stepName;
    });
  }
  if (serviceType == 'repair') {
    brandConfig.repairFormSteps.map((repairFormStep) => {
      formStepsList[Number(repairFormStep.stepIndex)] = repairFormStep.stepName;
    });
  }
  return formStepsList;
};

export const getFormStepKeys = (serviceType: string, brandConfig: BrandConfigurationModel) => {
  let formStepsKeysList: Array<string> = [];
  // console.log("brandConfig.repairFormStepsbrandConfig.repairFormSteps",brandConfig.repairFormSteps);
  if (serviceType == 'warranty') {
    brandConfig.warrantyFormSteps.map((warrantytFormStep) => {
      formStepsKeysList[Number(warrantytFormStep.stepIndex)] = warrantytFormStep.formStepKey;
    });
  }
  if (serviceType == 'repair') {
    brandConfig.repairFormSteps.map((repairFormStep) => {
      formStepsKeysList[Number(repairFormStep.stepIndex)] = repairFormStep.formStepKey;
    });
  }
  return formStepsKeysList;
};

export const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(' ');
};

export const getInitialInFocusList = (len: number) => {
  let inFocusList = [];
  for (let i = 0; i < len; i++) {
    inFocusList.push({ key: i, value: false });
  }
  return inFocusList;
};

//--------------------------
//--------------------------

export const updateFromLocalStorage = (formData: FormDataType, handleChange: any) => {
  handleChange(formData?.serviceType, 'serviceType');

  //

  handleChange(formData?.receiptAvailable, 'receiptAvailable');
  handleChange(formData?.receipt, 'receiptAvailable');
  handleChange(formData?.returnProduct, 'returnProduct');

  //

  handleChange(formData?.firstName, 'firstName');
  handleChange(formData?.lastName, 'lastName');
  handleChange(formData?.email, 'email');
  handleChange(formData?.phoneNumber, 'phoneNumber');
  handleChange(formData?.streetName, 'streetName');
  handleChange(formData?.postalCode, 'postalCode');
  handleChange(formData?.city, 'city');
  handleChange(formData?.country, 'country');

  //

  handleChange(formData?.isLabelsMissing, 'isLabelsMissing');
  handleChange(formData?.modelName, 'modelName');
  handleChange(formData?.modelNumber, 'modelNumber');
  handleChange(formData?.productionCode, 'productionCode');

  //

  handleChange(formData?.productCategory, 'productCategory');
  handleChange(formData?.productSubCategory, 'productSubCategory');

  //

  handleChange(formData?.damageDetails, 'damageDetails');

  //

  handleChange(formData?.logisticsData, 'logisticsData');
  handleChange(formData?.deliverylogisticsData, 'deliverylogisticsData');

  handleChange(formData?.pickUpDate, 'pickUpDate');
  handleChange(formData?.pickUpTime, 'pickUpTime');

  handleChange(formData?.deliveryDate, 'deliveryDate');
  handleChange(formData?.deliveryTime, 'deliveryTime');
};


export const getDamageOptionsList = (damageOptions: DamageRespModel[]) => {
  const damageOptionsWithIndex: DamageOptionModelNew[] = [];
  // let index = 0;
  // damageOptions.map((damageOption: DamageRespModel, index: number) => {

  // damageOptionsWithIndex.push({
  //   index: index,
  //   damageType: damageOption.damageType,
  //   subDamages: damageOption.subDamages,
  // });
  // });

  damageOptions.forEach((damageOption: DamageRespModel, index: number) => {
    const subDamage: SubDamageOptionModelNew = {
      damageID: Number(damageOption.id),
      damageType: damageOption.damage,
      subDamageType: damageOption.sub_damage,
      amount: damageOption.amount,
      currencyCode: "NOK",
      isUnderWarranty: damageOption.iswarranty,
    };

    const existingDamage = damageOptionsWithIndex.find(
      (option) => option.damageType === damageOption.damage
    );

    if (existingDamage) {
      if(!existingDamage.subDamages.includes(subDamage)){
        console.log("addin subdamage::: ",subDamage)
        existingDamage.subDamages.push(subDamage);

      }
    } else {
      damageOptionsWithIndex.push({
        index: index,
        damageType: damageOption.damage,
        subDamages: [subDamage],
      });
    }
  });
  return damageOptionsWithIndex;
};