import React, { useState, useEffect } from 'react';
import { bookDryCleanOrder, createStripePaymentIntent, getDryCleanProducts } from '../../utils/api';
import './DryCleanBooking.scss';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ApiKeyContext } from '../..';
import { PageLoader } from '../../components/UI/PageLoader';
import Layout from './Layout';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ShoppingCartRounded } from '@mui/icons-material';

type Product = {
  name: string;
  cost: number;
  count: number;
  id: number;
};

const languages: { [key: string]: string } = {
  Skirt: 'Skjorte',
  Dress: 'Dress',
  Blazer: 'Blazer',
  Pants: 'Bukse',
  Blouse: 'Bluse',
  Sweater: 'Genser',
  Coat: 'Frakk',
  'Long dress': 'Lang kjole',
  'Short dress': 'Kort kjole',
  'Light down jacket': 'Lett dunjakke',
};
const DryCleanBooking: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [bookingConfirmed, setBookingConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchingProducts, setFetchingProducts] = useState(true);
  const [fetchError, setFetchError] = useState('');
  const navigate = useNavigate();
  let apiKey = React.useContext(ApiKeyContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [recentlyAdded, setRecentlyAdded] = useState<number | null>(null); // State to track recently added product

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setFetchingProducts(true);
        const resp = await getDryCleanProducts();
        setProducts(resp);
      } catch {
        setFetchError('Failed to load products');
      } finally {
        setFetchingProducts(false);
      }
    };
    fetchProducts();
  }, []);

  const handleProductSelection = (product: Product) => {
    setSelectedProducts((prev) => {
      const existing = prev.find((p) => p.id === product.id);
      if (existing) return prev;
      return [...prev, { ...product, count: 1 }];
    });

    // Show "Added" feedback
    setRecentlyAdded(product.id);
    setTimeout(() => {
      setRecentlyAdded(null); // Reset after 1 second
    }, 1000);
  };

  const handleCountChange = (id: number, type: 'increase' | 'decrease') => {
    setSelectedProducts((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, count: type === 'increase' ? item.count + 1 : Math.max(1, item.count - 1) } : item,
      ),
    );
  };

  const handleRemoveProduct = (productId: number) => {
    setSelectedProducts((prev) => prev.filter((item) => item.id !== productId));
  };

  const sendCreatePaymentIntentDetails = React.useCallback(
    async (orderID: number, amountPaid: any, shippingCost: any) => {
      const args = {
        orderID,
        amountPaid: amountPaid,
        shippingCost: shippingCost,
        paymentMethodType: 'card',
        currency: 'nok',
      };

      await createStripePaymentIntent(apiKey, args).then((resp) => {
        if (!resp.error) {
          navigate({
            pathname: '/dryclean-booking/checkout',
            search: createSearchParams({ orderId: orderID.toString() }).toString(),
          });
        }
      });
    },
    [apiKey, navigate],
  );

  const handleBooking = async () => {
    if (selectedProducts.length > 0 && userName && email && phone && address) {
      setLoading(true);
      try {
        const totalCost = selectedProducts.reduce((acc, item) => acc + item.cost * item.count, 0);
        const resp = await bookDryCleanOrder({
          name: userName,
          email,
          phone,
          address,
          totalCost,
          products: selectedProducts.map((item) => ({
            id: item.id,
            count: item.count,
          })),
        });

        if (resp && resp.orderID) {
          await sendCreatePaymentIntentDetails(resp.orderID, totalCost + 25 + 10, 0);
        }
      } catch {
        alert('Bestilling ikke gjennomført. Vennligst prøv igjen.');
      } finally {
        setLoading(false);
      }
    } else {
      alert('Vennligst fyll inn alle felt');
    }
  };

  if (fetchingProducts) return <PageLoader />;
  if (fetchError) return <PageLoader content={fetchError} />;

  return (
    <div className="booking-container">
      <div className="booking-form">
        <h2>{bookingConfirmed ? 'Ordreoversikt' : 'Bestill Rens'}</h2>

        {/* Product Section */}
        <div className="product-section">
          <div className="form-group">
            <button onClick={() => setModalOpen(true)} className="modal-open-button">
              <ShoppingCartRounded />
              <span>Legg til produkter</span>
            </button>
          </div>

          {selectedProducts.map((item) => (
            <div key={item.id} className="selected-product">
              <p className="product-name">
                {languages[item.name as keyof typeof languages] ?? item.name} -{' '}
                <span className="product-price">{item.cost * item.count} NOK</span>
              </p>
              <div className="count-control">
                <button onClick={() => handleCountChange(item.id, 'decrease')} className="count-button">
                  -
                </button>
                <span className="count">{item.count}</span>
                <button onClick={() => handleCountChange(item.id, 'increase')} className="count-button">
                  +
                </button>
                <button onClick={() => handleRemoveProduct(item.id)} className="remove-button">
                  Fjern
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Customer Details Section */}
        <div className="customer-details-section">
          <div className="form-group">
            <label htmlFor="name">Navn</label>
            <input
              id="name"
              type="text"
              placeholder="Ditt Navn"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              placeholder="Din Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">Telefon</label>
            <input
              id="phone"
              type="tel"
              placeholder="Ditt Telefonnummer"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="address">Adresse</label>
            <input
              id="address"
              type="text"
              placeholder="Din adresse"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>

          <button onClick={handleBooking} className="booking-button" disabled={loading}>
            {loading ? (
              <div className="loader">
                <span className="loader-inner"></span>
              </div>
            ) : (
              <span>Bestill</span>
            )}
          </button>

          <p
            style={{
              margin: '5px',
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            Pakk klærne i en pose og skriv navnet ditt utenpå
          </p>
        </div>
      </div>

      {/* Modal for Product Selection */}
      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Velg produkter</h3>
            <ul>
              {products.map((product) => (
                <li key={product.id} className="product-item">
                  <span>
                    {languages[product.name as keyof typeof languages] ?? product.name} - {product.cost} NOK
                  </span>
                  <button
                    onClick={() => handleProductSelection(product)}
                    className={`add-product-button ${recentlyAdded === product.id ? 'added' : ''}`}
                    disabled={recentlyAdded === product.id} // Disable if recently added
                  >
                    {recentlyAdded === product.id ? 'Lagt til' : 'Legg til'}
                  </button>
                </li>
              ))}
            </ul>
            <button onClick={() => setModalOpen(false)} className="close-modal-button">
              Lukk
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DryCleanBooking;
