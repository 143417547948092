import { configureStore } from '@reduxjs/toolkit';
import { brandDataReducer } from './BrandData/brandDataSlice';
import { productDataReducer } from './ProductData/CategoryData/categoryDataSlice';
import { discountCodeDataReducer } from './PricingData/DiscountCodeData/discountCodeDataSlice';

export const store = configureStore({
  reducer: {
    brandData: brandDataReducer,
    productData: productDataReducer,
    discountData: discountCodeDataReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
