import { createSlice } from '@reduxjs/toolkit';
import { brandDataState } from './brandDataTypes';
import { BrandConfigurationModel } from '../../types/brand/brand.model';

const initialState: brandDataState = {
  brandDetails: [],
  brandConfiguration: {} as BrandConfigurationModel,
};

const brandDataSlice = createSlice({
  name: 'brandData',
  initialState,
  reducers: {
    setBrandData: (state, action) => {
      state.brandDetails = action.payload;
    },
    setBrandConfiguration: (state, action) => {
      state.brandConfiguration = action.payload;
    },
  },
});

export const brandDataReducer = brandDataSlice.reducer;
