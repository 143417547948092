import React, { useState } from 'react';
import { ApiKeyContext, BrandsListContext } from '../..';
import { PageLoader } from '../../components/UI/PageLoader';
import { getOrderDetails, updateOrderPaymentStatus, getDiscountCodeDetails, getRepairerStudioDetails, createPostnordShipment } from '../../utils/api';
import { useSearchParams } from 'react-router-dom';
import { Text } from '../../components/UI/Text';
import './order-summary.scss';
import ErrorIcon from '@mui/icons-material/Error';
import { RepairDetailData } from '../../types/order/order.model';
import { PaymentStatusEnum } from '../../types/enum/order.enum';
import { BrandContext } from '../../App';
import { OrderSummaryContactInfo } from './order-summary-contactInfo';
import { OrderSummaryHeader } from './order-summary-header';
import { OrderSummaryRepairInfo } from './order-summary-repairInfo';
import { OrderSummaryShippingInfo } from './order-summary-shippingInfo';
import { OrderSummaryTotalInfo } from './order-summary-totalInfo';
import { formatPostnordShipmentArgs, formatShipmentArgs } from './utils';

export const OrderSummary: React.FC = () => {
  let [loading, setLoading] = React.useState<boolean>(false);
  let [orderData, setOrderData] = React.useState<RepairDetailData>({} as RepairDetailData);
  let [error, setError] = React.useState<string | null>(null);
  let underWarranty = false;
  const [discountedAmount, setDiscountedAmount] = React.useState(0);
  const [searchParams] = useSearchParams();
  let apiKey = React.useContext(ApiKeyContext);
  const brandDetails = React.useContext(BrandContext);
  const brandsList = React.useContext(BrandsListContext);

  const [shippingLabel , setShippingLabel] = useState("");

  // console.log('BrandDetails: ', brandsList);
  let brandID = brandsList.find(
    (brand) => brand.brandName.toUpperCase() === brandDetails?.brandName.toUpperCase(),
  )?.brandId;

  const calculateDiscountedAmount = React.useCallback(async (fetchedOrderData: RepairDetailData) => {
    if (!!fetchedOrderData.discountCode) {
      await getDiscountCodeDetails(fetchedOrderData.discountCode, brandID ?? 6)
        .then((resp) => {
          let calculatedDiscount = 0;
          if (!!resp) {
            if (resp.discountPercentage !== 0) {
              calculatedDiscount = (resp.discountPercentage * fetchedOrderData.totalCost) / 100;
              if (resp.maxDiscountAmount > 0) {
                calculatedDiscount = Math.min(calculatedDiscount, resp.maxDiscountAmount);
              }
            } else {
              calculatedDiscount = resp.discountAmount;
            }
            setDiscountedAmount(calculatedDiscount);
          } else {
            setDiscountedAmount(0);
          }
        })
        .catch((error) => {
          console.log('Failed to fetch the Discount Code');
        });
    }
  }, []);

  const updateOrderPayment = React.useCallback(async (OrderID: number) => {
    await updateOrderPaymentStatus(apiKey, OrderID)
      .then(async () => {
        await getOrderDetails(apiKey, OrderID).then(async (data) => {
          setOrderData(data);
          calculateDiscountedAmount(data);
          console.log("book shipment...",data);

          if(orderData.paymentStatus === PaymentStatusEnum.PAID || orderData.paymentStatus === PaymentStatusEnum.SUCCEEDED) {

            if(data && data.shipmentDetails && data.shipmentDetails.pickupDetail && (data.shipmentDetails.pickupDetail.productCode == 92)) {
              await getRepairerStudioDetails(apiKey,OrderID).then(async (repairerStudioData) => {
  
                console.log("repairer data...",repairerStudioData);
                await createPostnordShipment(formatPostnordShipmentArgs(data, repairerStudioData, OrderID)).then((response) => {
                  console.log("response",response);
                  if(response.shipmentLabel) {
                    const label = response.shipmentLabel;
                    const labelLink = document.createElement("a");
                    labelLink.href = "https://"+label;
                    labelLink.target = "_blank";
                    labelLink.download = `ShipmentLabel_${OrderID}.pdf`;
                    document.body.appendChild(labelLink);
                    labelLink.click();
                    document.body.removeChild(labelLink);
                    setShippingLabel(response.shipmentLabel);
  
                  }
    
                });
                // await createShipment(formatShipmentArgs(data, repairerStudioData, OrderID)).then((response) => {});
              });
            }
          }

          
           


          // await getRepairerStudioDetails(OrderID).then(async (repairerStudioData) => {
          //   await createShipment(formatShipmentArgs(data, repairerStudioData, OrderID)).then((response) => {});
          // });
        });
      })
      .catch((e) => {
        console.log('Error:', e);
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    const orderID = Number(searchParams.get('OrderID'));
    if (orderID) {
      updateOrderPayment(orderID);
    } else {
      setLoading(false);
      setError('error');
    }
  }, [searchParams, updateOrderPayment]);

  underWarranty = orderData?.damageDetails?.some((damage: any) => {
    return damage.isUnderWarranty;
  });

  return loading ? (
    <PageLoader content="Loading Order Summary Page. Please do not reload or refresh." />
  ) : (
    <div className="order-summary">
      {error ? (
        <div className="error-info">
          <ErrorIcon />
          <Text
            text="Some error occured."
            className={`${brandDetails.brandName}-description-bold small-screen-font-size`}
          />
        </div>
      ) : orderData.paymentStatus === PaymentStatusEnum.PAID ||
        orderData.paymentStatus === PaymentStatusEnum.SUCCEEDED ? (
        <div className="order-details">
          <OrderSummaryHeader shipmentLabel ={shippingLabel} orderData={orderData} brandDetails={brandDetails} />
          <hr />
          <div className="section">
            <OrderSummaryContactInfo brandDetails={brandDetails} orderData={orderData} />
          </div>
          <hr />
          <div className="section">
            <OrderSummaryRepairInfo orderData={orderData} brandDetails={brandDetails} />
          </div>
          <hr />
          <div className="section">
            {/* <div className="estimate">
              <Text text={messages.repairBookingForm.summary.estimatePrice} className="description-small" />
              <Text text={`${orderData.currencyCode} ${orderData.totalCost}`} className="description-small" />
            </div> */}
            <OrderSummaryShippingInfo orderData={orderData} brandDetails={brandDetails} underWarranty={underWarranty} />
          </div>
          {!!orderData.discountCode && (
            <div className="cost">
              <Text
                text={orderData.discountCode}
                className={`${brandDetails.brandName}-description bold accent small-screen-font-size`}
              />
              <Text
                text={`- ${orderData.currencyCode} ${discountedAmount}`}
                className={`${brandDetails.brandName}-description bold accent small-screen-font-size`}
              />
            </div>
          )}
          <hr />
          <div className="section">
            <OrderSummaryTotalInfo orderData={orderData} brandDetails={brandDetails} />
          </div>
        </div>
      ) : (
        <div className="error-info">
          <ErrorIcon />
          <Text
            text="Payment failed. Please try again."
            className={`${brandDetails.brandName}-description-bold small-screen-font-size`}
          />
        </div>
      )}
    </div>
  );
};
