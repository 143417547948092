export const getBrandMapping = (brandsList: Array<any>): Array<{ label: string; value: string }> => {
  let brands = new Set<string>();
  let list: Array<{ label: string; value: string }> = [];
  brandsList.forEach((brand: any) => {
    const { brandName, brandId } = brand;
    if (!brands.has(brandName)) {
      brands.add(brandName);
      let obj = {
        label: brand.brandName,
        value: brand.brandId,
      };
      if (list.indexOf(obj) === -1) {
        list.push(obj);
      }
    }
  });
  return list;
};
