import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.scss';
import './i18nextConf';
import './blobConfig';
import { getBrandsList, validateTenantKey } from './utils/api';
import { BrandConfigurationModel, BrandsListModel } from './types/brand/brand.model';
import { RepairableBrandDetails } from './utils';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { PageLoader } from './components/UI/PageLoader';

// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') || '';
export const ApiKeyContext = React.createContext('');
export const BrandConfigurationContext = React.createContext({} as BrandConfigurationModel);

export const BrandsListContext = React.createContext([] as BrandsListModel[]);
export const TemplateTypeContext = React.createContext('');

// const localBrand = '2'; //for AWAN
// const localBrand = '1'; //for BRGN
// const localBrand = '6'; //for REPAIRABLE

document.addEventListener('DOMContentLoaded', async () => {

  localStorage.removeItem('formData');
  const brandsList = await getBrandsList();
  var parenturl = window.location !== window.parent.location ? document.referrer : document.location.origin + "/";

  let { AccessKey, Configuration, BrandName, TemplateType } = await validateTenantKey(parenturl);

  // console.log('Parent URL:', parenturl.includes('integration_id'));
  // console.log('Config', Configuration);
  // if (Configuration === null || Configuration === undefined) {
    // Configuration = JSON.stringify(RepairableBrandDetails());
    // console.log('New Config:', Configuration);
  // }



  if (AccessKey === null || AccessKey === undefined) {
    AccessKey = 'e60aa6f84c393ef4ea99c1b7de41c0d799344e79d9ad2267d7e37282ce8379a8';
  }
  // console.log('Running Env:', process.env);
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <BrowserRouter basename="/">
      <Provider store={store}>
        <BrandConfigurationContext.Provider value={JSON.parse(Configuration)}>
          <BrandsListContext.Provider value={brandsList}>
            <TemplateTypeContext.Provider value={TemplateType}>
              <ApiKeyContext.Provider value={AccessKey}>
                <App />
              </ApiKeyContext.Provider>
            </TemplateTypeContext.Provider>
          </BrandsListContext.Provider>
        </BrandConfigurationContext.Provider>
      </Provider>
    </BrowserRouter>,
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
