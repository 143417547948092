import React from 'react';
import { Text } from '../../components/UI/Text';
import { RepairDetailData } from '../../types/order/order.model';
import { messages } from '../../Messages';
import SuccessIcon from '../../icons/green_tick.svg';

interface Props {
  orderData: RepairDetailData;
  brandDetails: any;
  shipmentLabel?: string;
}

export const OrderSummaryHeader: React.FC<Props> = ({ orderData, brandDetails, shipmentLabel }) => {
  // const shipmentLabel = getva
  console.log('order data.... ', orderData);

  return (
    <div className="summary-header">
      <img src={SuccessIcon} alt="Success" />
      <Text
        text={`Hi ${orderData.firstName}, Your order is confirmed`}
        className={`${brandDetails.brandName}-description-bold small-screen-font-size`}
      />
      <div className="disclaimer">
        <Text
          text={messages.repairBookingForm.summary.labelDisclaimer}
          className={`${brandDetails.brandName}-description-small small-screen-font-size`}
        />

        {shipmentLabel ? <a href={'https://' + shipmentLabel}>Download Shipment Label</a> : null}
      </div>
    </div>
  );
};
