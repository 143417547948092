import { BlobServiceClient } from "@azure/storage-blob";
import { getReadSasUrl, getUploadSasUrl} from './utils/api'

const productImagesContainer = "categorysketch";
const readSasToken = process.env.REACT_APP_AZURE_READ_SAS_TOKEN;
const uploadSasToken = process.env.REACT_APP_AZURE_UPLOAD_SAS_TOKEN;
const storageAccountName = process.env.REACT_APP_AZURE_STORAGE_RESOURCE_NAME;

export const isStorageConfigured = () => {
  return !storageAccountName || !readSasToken || !uploadSasToken ? false : true;
};

export const getBlobsInContainer = async (apikey, blobName, fileName) => {
  return await getReadSasUrl(apikey, productImagesContainer, `${blobName}/${fileName}`);
  //return `https://${storageAccountName}.blob.core.windows.net/${productImagesContainer}/${blobName}/${fileName}?${readSasToken}`
};

// container - receipts / damaged product images
// blobName - order ID
// file - file to upload
const createBlobInContainer = async (apikey, container, blobName, file) => {
  const uploadUrl = await getUploadSasUrl(apikey, container, `${blobName}/${file.name}`);
  //const uploadUrl = `https://${storageAccountName}.blob.core.windows.net?${uploadSasToken}`;
  const blobService = new BlobServiceClient(uploadUrl);
  const containerClient = blobService.getContainerClient(container)
  const blockBlobClient = containerClient.getBlockBlobClient(`${blobName}/${file.name}`);
  const options = { blobHTTPHeaders: { blobContentType: file.type } };
  await blockBlobClient.uploadData(file, options);
};

const uploadFileToBlob = async (apikey, container, blobName, file) => {
  if (!file) return;
  await createBlobInContainer(apikey, container, blobName, file);
};

export default uploadFileToBlob;
