import React from 'react';
import './home.scss';
import { Button } from '../../components/UI/Button';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../components/UI/Text';
import { BrandContext } from '../../App';
import { BrandConfigurationContext } from '../..';
import RepairableLogo from '../../images/Repairable_Logo.svg';
import { PageLoader } from '../../components/UI/PageLoader';
import { getBrandMapping } from './utils';
import { useDispatch } from 'react-redux';
import { messages } from '../../Messages';

const Logo = React.lazy(() => import('../../components/UI/Logo/index'));

export const GenericBrandContext = React.createContext({});

export const GenericHome: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [logoSource, setLogoSource] = React.useState('');

  const brandDetails = React.useContext(BrandContext);
  const brandConfiguration = React.useContext(BrandConfigurationContext);

  const onEnterClick = () => {
    setLoading(true);
    dispatch({ type: 'brandData/setBrandData', payload: brandDetails });
    navigate('/repair-booking', { state: { serviceType: 'repair' } });
    setLoading(false);
  };

  const getBrandLogoAndList = React.useCallback(() => {
    try {
      setLoading(true);
      setLogoSource(RepairableLogo);
    } catch {
      console.log('Error fetching the logo');
    } finally {
      setLoading(false);
    }
  }, []);

  const handleLogoClick = () => {
    window.open('https://www.repairable.community', '_blank');
  };

  React.useEffect(() => {
    setLoading(true);
    getBrandLogoAndList();
    setLoading(false);
  }, [getBrandMapping]);

  return (
    <div className="generic-home-page">
      {brandConfiguration && brandDetails && (
        <>
          <div className="generic-home-left container-item">
            <div className="logo-container">
              {logoSource && (
                <React.Suspense fallback="">
                  <Logo imageSource={logoSource} altText="image" className="brand-logo" onClick={handleLogoClick} />
                </React.Suspense>
              )}
            </div>
            <div className="generic-home-content">
              <Text
                text={brandConfiguration.homePageHeader}
                className={`${brandDetails.brandName}-heading heading-dynamic`}
              />
              <div className="generic-home-description">
                <Text
                  text={brandConfiguration.homePageDescription}
                  className={`${brandDetails.brandName}-description description-dynamic`}
                />
              </div>
              <div className="action-button">
                <Button
                  variant="contained"
                  className={`${brandDetails.brandName}-font order-repair`}
                  buttonText={messages.home.button}
                  onClick={onEnterClick}
                  disabled={!brandDetails}
                />
              </div>
              <div className="footer-text">
                <Text
                  text="Service charge is to be paid by you."
                  className={`${brandDetails?.brandName}-description-small italics`}
                />
              </div>
            </div>
          </div>
          <div className="generic-home-right container-item">
            <div className="background-image-container"></div>
          </div>
        </>
      )}
      {loading && <PageLoader className="overlay" />}
    </div>
  );
};
