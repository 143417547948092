export const messages = {
  home: {
    header: 'landingPageHeader',
    description: 'landingPageDescription',
    button: 'orderRepairButton',
    next: 'next',
  },
  dynamicHome: {
    header: 'dynamicHomeHeader',
  },
  repairBookingForm: {
    header: 'orderRepairDetails',
    back: 'back',
    next: 'next',
    submit: 'submit',
    warrantyCheck: {
      header: 'warrantyCheck',
      description: 'warrantyCheckDescription',
      service: 'selectService',
      repair: 'repairService',
      warranty: 'warrantyClaim',
    },
    documentationCheck: {
      header: 'documentationCheck',
      description: 'documentationCheckDescription',
      label: 'fileUploadLabel',
      hint: 'fileUploadHint',
      receiptAvailable: 'receiptAvailableLabel',
      underWarranty: 'underWarrantyLabel',
      underWarrantyDescription: 'underWarrantyDescription',
      returnProduct: 'returnProduct',
      contact: 'contact',
      missingDocument: 'missingDocument',
      info: 'documentationCheckInfo',
      returnProductCheck: 'returnProductCheckLabel',
    },
    contactDetails: {
      header: 'contactDetails',
      description: 'contactDetailsDescription',
      firstName: 'firstName',
      lastName: 'lastName',
      phoneNumber: 'phoneNumber',
      email: 'email',
      address: 'address',
      streetName: 'streetName',
      streetNumber: 'streetNumber',
      placeholder: 'addressPlaceholder',
      postalCode: 'postalCode',
      city: 'city',
      country: 'country',
      locations: 'dropLocations',
    },
    productDetails: {
      header: 'productDetails',
      description: 'productDetailsDescription',
      modelName: 'modelName',
      modelNumber: 'modelNumber',
      PONumber: 'PONumber',
      labelMissingCheck: 'labelMissingCheck',
      productCategoryDescription: 'productCategoryDescription',
    },
    damageDetails: {
      header: 'damageDetails',
      description: 'damageDetailsDescription',
      addDamage: 'addDamage',
      selectDamageType: 'selectDamageType',
      imageInstruction: 'damageInstruction',
      repairableCost: 'repairableCost',
      total: 'total',
      comment: 'comment',
      commentDescription: 'commentDescription',
      label: 'fileUploadLabel',
      imageFormats: 'damagedProductImageFormats',
      removeButton: 'removeButton',
      holeDamageDisclaimer: 'holeDamageDisclaimer',
    },
    pickupDetails: {
      header: 'pickupDetails',

      description: 'pickupDetailsDescription',
      pickupOption: 'pickupOption',
      shipment: 'shipment',
      distance: 'distance',
      time: 'time',
      date: 'date',
      price: 'price',
    },
    returnDetails: {
      header: 'returnDetails',
      description: 'returnDetailsDescription',
      pickupOption: 'deliveryOption',
      shipment: 'shipment',
      distance: 'distance',
      time: 'time',
      date: 'date',
      price: 'price',
    },
    summary: {
      header: 'summary',
      productInfo: 'productInfo',
      contactInfo: 'contactInfo',
      repair: 'repair',
      total: 'total',
      payNowButton: 'payNowButton',
      estimatePrice: 'estimatePriceSummary',
      shippingBack: 'shippingBack',
      underWarranty: 'underWarranty',
      labelDisclaimer: 'labelDisclaimer',
    },
  },
  common: {
    note: 'note',
    notFound: 'notFound',
    estimatePrice: 'estimatePrice',
    shippingCost: 'shippingCost',
    returnShippingCost: 'Return Shipping Cost',
  },
  validation: {
    requiredField: 'requiredFieldErrorText',
    invalidPostalCode: 'invalidPostalCode',
    noDropLocation: 'noDropLocation',
  },
  error: {
    apiErrorHeading: 'apiErrorHeading',
    apiErrorDescription: 'apiErrorDescription',
  },
};
