import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button } from '../../components/UI/Button';
import { Text } from '../../components/UI/Text';
import { CARD_ELEMENT_OPTIONS } from './constants';
import { TextInput } from '../../components/UI/TextInput';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { StripeCardElement } from '@stripe/stripe-js';
import { RepairDetailData } from '../../types/order/order.model';
import { CreditCard } from '@mui/icons-material';
import React from 'react';
import { PageLoader } from '../../components/UI/PageLoader';
import { getPaymentIntentSecret } from '../../utils/api';

interface Props {
  brandDetails: any;
  orderData: RepairDetailData;
  apiKey: string;
}

export const CheckoutCardAndPaymentInfo: React.FC<Props> = ({ brandDetails, orderData, apiKey }) => {
  const [nameOnCard, setNameOnCard] = React.useState<string>('');
  const [orderID, setOrderID] = React.useState<Number>(0);
  const [searchParams] = useSearchParams();

  const [error, setError] = React.useState<string>('');
  const [clientSecret, setClientSecret] = React.useState<string>('');

  const [loading, setLoading] = React.useState<boolean>(false);
  const [payNowClicked, setPayNowClicked] = React.useState(true);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const handleConfirmPayment = async () => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // e.preventDefault();

    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        window.alert('Stripe.js has not yet loaded.');
        return;
      }

      const cardElement = elements.getElement(CardElement) as StripeCardElement;
      if (!cardElement) {
        return;
      }

      //   console.log(process.env.REACT_APP_PUBLIC_URL);
      setTimeout(async () => {
        const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret.toString(), {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: orderData.firstName + ' ' + orderData.lastName,
            },
          },
          return_url: process.env.REACT_APP_PUBLIC_URL + '/order-summary' + '?success=true&OrderID=' + orderID,
        });
        let displayError = document.getElementById('card-errors') as HTMLElement;

        if (stripeError) {
          displayError.textContent = stripeError.message as string;
          console.log('transaction error');
          // Show error to your customer (e.g., insufficient funds)
          return;
        } else {
          displayError.textContent = '';
        }

        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        navigate({
          pathname: '/order-summary',
          search: createSearchParams({ OrderID: orderID.toString(), success: 'true' }).toString(),
        });
      }, 500);
      // window.alert(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
      // console.log('success')
    } catch (error) {
      console.log('Error');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = React.useCallback(() => {
    setLoading(true);
    try {
      handleConfirmPayment();
    } catch (error) {
      console.log('failed');
      setLoading(false);
    } finally {
    }
  }, [setLoading, handleConfirmPayment]);

  const fetchPaymentIntent = React.useCallback(
    async (ID: number) => {
      await getPaymentIntentSecret(apiKey, ID)
        .then(async (resp) => {
          setClientSecret(resp.data);
          setPayNowClicked(false);
        })
        .catch((e) => {
          setError(e);
          console.log('Error:');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [setLoading, apiKey],
  );

  React.useEffect(() => {
    setLoading(true);
    const ID = Number(searchParams.get('orderID'));
    if (ID) {
      setOrderID(ID);
      // console.log('ID:', ID);
      fetchPaymentIntent(ID);
    } else {
      setLoading(false);
      setError('error');
    }
  }, [searchParams, setLoading, fetchPaymentIntent]);

  return loading ? (
    <PageLoader className="contained" content="Processing" />
  ) : (
    <form id="payment-form">
      <div className="card-icons"></div>
      <Text text="Payment Method" className={`${brandDetails.brandName}-description-bold`} />
      <div className="payment-methods">
        <Button buttonText="CARD" variant="outlined" size="md" startIcon={<CreditCard />} disabled />
      </div>
      <Text text="Card Information" className={`${brandDetails.brandName}-description-small`} />
      <CardElement id="card" options={CARD_ELEMENT_OPTIONS} />
      <div id="card-errors" role="alert"></div>
      <Text text="Name on card" className={`${brandDetails.brandName}-description`} />
      <TextInput name="name" handleChange={(value: string) => setNameOnCard(value)} />

      <Button
        buttonText="Pay Now"
        onClick={() => {
          setPayNowClicked(true);
          handleSubmit();
          setPayNowClicked(false);
        }}
        className={`${brandDetails.brandName}-font`}
        disabled={payNowClicked === true}
      />
    </form>
  );
};
