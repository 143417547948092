import axios from 'axios';

let ip = process.env.REACT_APP_DISCOUNT_API_URL;

const headers: any = {
  'Access-Control-Allow-Credentials': true,
  Authorization: 'Basic ' + window.btoa('repairable_admin:p@55w0rd'),
};

export const rootPath = ip + '';

const discountApi = axios.create({
  baseURL: rootPath,
  headers: headers,
});

discountApi.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    if (error && error.response && error.response.status === 401) {
      console.log(error);
    }
    return Promise.reject(error);
  },
);

export default discountApi;
