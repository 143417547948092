import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Text } from '../Text';
import { Button } from '../Button';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import './Modal.scss';
import { messages } from '../../../Messages';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 420,
  bgcolor: '#001F2E',
  border: '1px solid #000',
  borderRadius: '1rem',
  boxShadow: 24,
  p: 4,
};

interface Props {
  open: boolean;
  handleClose: (value: boolean) => void;
  orderID: number;
  lineItems: any;
  setSelected: (value: number) => void;
  selected: number;
  setLoading: (value: boolean) => void;
}

export const MountedModal: React.FC<Props> = ({
  open,
  handleClose,
  orderID,
  lineItems,
  setSelected,
  selected,
  setLoading,
}) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [lineItemDetails, setLineItemDetails] = React.useState(null);
  const navigate = useNavigate();
  const [contextValue, setContextValue] = React.useState({});

  const handleItemClick = (index: number) => {
    setSelected(index);
  };

  const handleNextClick = React.useCallback(() => {
    try {
      setLoading(true);
      setLineItemDetails(lineItems[selected]);
      navigate('/repair-booking', { state: { lineItemDetails: lineItems[selected] } });
    } finally {
      setLoading(false);
    }
  }, [lineItems, selected, navigate]);

  React.useEffect(() => {
    if (lineItemDetails !== undefined && lineItemDetails !== null) {
      setContextValue({ lineItemDetails });

      // navigate('/repair-booking');
    }
  }, [lineItemDetails, navigate, contextValue]);

  const memoizedContextValue = React.useMemo(() => contextValue, [contextValue]);

  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <div className="modal-box">
            <div className="header">
              <Text text="SELECT ITEMS FOR REPAIR" className="description white" />
              <Text text={`Order id - ${orderID}`} className="white" />
            </div>
            <div className="body">
              <div className="line-items-container">
                {!!lineItems &&
                  lineItems?.map(
                    (lineItem: any, index: number) =>
                      index === currentIndex && (
                        <div className="line-item" key={index}>
                          <div className="carousel-left">
                            <Button
                              buttonText={``}
                              startIcon={<KeyboardArrowLeft style={{ color: index === 0 ? 'gray' : 'white' }} />}
                              variant="text"
                              disabled={index === 0}
                              className="carousel"
                              onClick={() => setCurrentIndex(currentIndex - 1)}
                            />
                          </div>
                          <div
                            className={`item-info ${selected === index && 'selected'}`}
                            onClick={() => handleItemClick(index)}
                          >
                            <img src={lineItem.product_image.src} alt="image" />
                            <Text
                              text={`${lineItem.orderLineitem.title}`}
                              className={`description-med bold shorter-line-height ${selected === index && 'white'}`}
                            />
                          </div>
                          <div className="carousel-right">
                            <Button
                              buttonText={``}
                              startIcon={
                                <KeyboardArrowRight
                                  style={{ color: index === lineItems.length - 1 ? 'gray' : 'white' }}
                                />
                              }
                              variant="text"
                              disabled={index === lineItems.length - 1}
                              className="carousel"
                              onClick={() => setCurrentIndex(currentIndex + 1)}
                            />
                          </div>
                        </div>
                      ),
                  )}
              </div>
              <div className="next-button">
                <Button
                  buttonText={messages.home.next}
                  size="medium"
                  variant="outlined"
                  className="button"
                  onClick={handleNextClick}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
