import axios from "axios";

const ip = process.env.REACT_APP_DRY_CLEAN_API_URL;

export const rootPath = ip + "";

const dryCleanApi = axios.create({
  baseURL: rootPath,
  // headers: headers,
});

// Add a response interceptor
dryCleanApi.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    if (error && error.response && error.response.status === 401) {
      console.log(error);
    }
    return Promise.reject(error);
  }
);

export default dryCleanApi;
