import { array, boolean, object, string } from 'yup';
import { messages } from '../../Messages';

export const schema = (activeStep: string, productForm?: string, contactForm?: string) => {
  let formSchema: any = {
    'Warranty Check': object().shape({
      serviceType: string().required(messages.validation.requiredField),
    }),
    'Documentation Check': object()
      .shape({
        receipt: string(),
        receiptAvailable: boolean(),
      })
      .test('select-one', messages.validation.requiredField, (value) => {
        const { receipt, receiptAvailable } = value;
        return !!receipt || !!receiptAvailable;
      }),
    'Contact Details':
      contactForm === 'info'
        ? object().shape({
            firstName: string().required(messages.validation.requiredField),
            lastName: string().required(messages.validation.requiredField),
            phoneNumber: string().required(messages.validation.requiredField),
            email: string().email().required(messages.validation.requiredField),
            streetName: string().required(messages.validation.requiredField),
            postalCode: string().required(messages.validation.requiredField),
            city: string().required(messages.validation.requiredField),
            country: string().required(messages.validation.requiredField),
          })
        : object().shape({
            dropLocation: object().required(messages.validation.requiredField),
          }),
    'Product Details':
      productForm === 'info'
        ? object()
            .shape({
              productInfo: boolean(),
              modelName: string(),
              modelNumber: string(),
              productionCode: string(),
              isLabelsMissing: boolean(),
            })
            .test('select-one', messages.validation.requiredField, (value) => {
              const { modelName, modelNumber, productionCode, isLabelsMissing } = value;
              // if (!modelName && !modelNumber && !productionCode) {
              //   object().shape({ isLabelsMissing: boolean().required(messages.validation.requiredField) });
              // }
              return !!modelName || !!modelNumber || !!productionCode || !!isLabelsMissing;
            })
        : object().shape({
            productCategory: string().required(messages.validation.requiredField),
            productSubCategory: string().required(messages.validation.requiredField),
          }),
    'Damage Details': object().shape({
      damageDetails: array().min(1).required(messages.validation.requiredField),
    }),
    'Pick-Up Details': object().shape({
      logisticsData: string().required(messages.validation.requiredField),
    }),
    'Return Details': object().shape({
      deliverylogisticsData: string().required(messages.validation.requiredField),
    }),
    Summary: object().shape({}),
  };
  return formSchema[activeStep];
};
