import React from 'react';
import { RepairBookingForm } from '../../components/RepairBookingForm';
import { BrandContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import { BrandConfigurationContext } from '../..';

export const RepairBooking: React.FC = () => {
  const brandDetails = React.useContext(BrandContext);
  const brandConfiguration = React.useContext(BrandConfigurationContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!brandDetails || !brandConfiguration) {
      navigate('/');
    }
  }, [brandDetails, navigate]);

  return <RepairBookingForm />;
};
