import React from 'react';
import './Text.scss';
import { useTranslation } from 'react-i18next';
import './AWANFontsText.scss';
import './BRGNFontsText.scss';
import './RepairableFontsText.scss';

interface Props {
  text: string;
  className?: string;
}

export const Text: React.FC<Props> = ({ text, className = '' }) => {
  const { t } = useTranslation();
  return <div className={`text ${className}`}>{t(text)}</div>;
};
