import {
  ShipmentBookingDataModel,
  ShipmentConsignmentModel,
  ShipmentConsignmentPackageModel,
  ShipmentConsignmentPackageDimensionsModel,
  ShipmentConsignmentPartiesModel,
  ShipmentConsignmentPartiesSenderModel,
  ShipmentConsignmentPartiesContactModel,
  ShipmentConsignmentPartiesRecipientModel,
} from '../../types/logistics/bring.model';
import { CustomerModel, PostnordPackageModel } from '../../types/logistics/logisticsOptions.model';
import { PostnordBookingDataModel } from '../../types/logistics/postnord';

export const formatShipmentArgs = (orderData: any, repairerStudioData: any, OrderID: any) => {
  let bookingData = {} as ShipmentBookingDataModel;
  bookingData.orderId = OrderID;
  bookingData.consignments = [] as Array<ShipmentConsignmentModel>;
  let consignmentData = {} as ShipmentConsignmentModel;
  consignmentData.correlationId = OrderID.toString();
  consignmentData.packages = [] as Array<ShipmentConsignmentPackageModel>;
  let packageData = {} as ShipmentConsignmentPackageModel;
  packageData.correlationId = OrderID.toString();
  packageData.dimensions = {
    heightInCm: 10,
    lengthInCm: 10,
    widthInCm: 10,
  } as ShipmentConsignmentPackageDimensionsModel;
  packageData.weightInKg = 2;
  consignmentData.packages.push(packageData);
  consignmentData.parties = {} as ShipmentConsignmentPartiesModel;
  consignmentData.parties.sender = {} as ShipmentConsignmentPartiesSenderModel;
  consignmentData.parties.sender.addressLine = orderData.address;
  consignmentData.parties.sender.city = orderData.city;
  consignmentData.parties.sender.countryCode = orderData.country;
  consignmentData.parties.sender.name = orderData.firstName + orderData.lastName;
  consignmentData.parties.sender.postalCode = orderData.postalCode;
  consignmentData.parties.sender.contact = {
    phoneNumber: orderData.phoneNumber,
    name: orderData.firstName + orderData.lastName,
    email: orderData.email,
  } as ShipmentConsignmentPartiesContactModel;

  consignmentData.parties.recipient = {} as ShipmentConsignmentPartiesRecipientModel;
  consignmentData.parties.recipient.addressLine = repairerStudioData.addressLine;
  consignmentData.parties.recipient.addressLine2 = repairerStudioData.addressLine2;
  consignmentData.parties.recipient.city = repairerStudioData.city;
  consignmentData.parties.recipient.countryCode = repairerStudioData.countryCode;
  consignmentData.parties.recipient.name = repairerStudioData.repairerStudioName;
  consignmentData.parties.recipient.postalCode = repairerStudioData.postalCode;
  consignmentData.parties.recipient.contact = {
    phoneNumber: repairerStudioData.phoneNumber,
    name: repairerStudioData.repairerStudioName,
    email: repairerStudioData.email,
  } as ShipmentConsignmentPartiesContactModel;
  const currentTimeAsMs = Date.now();
  const adjustedTimeAsMs = currentTimeAsMs + 1000 * 60 * 60 * 24 * 3;
  consignmentData.shippingDateTime = adjustedTimeAsMs;

  bookingData.consignments.push(consignmentData);
  return bookingData;
};

export const formatPostnordShipmentArgs = (orderData: any, repairerStudioData: any, OrderID: any) => {
  let bookingData = {} as PostnordBookingDataModel;

  bookingData.consignee = {} as CustomerModel;
  bookingData.consignee.name = repairerStudioData.repairerStudioName;
  bookingData.consignee.city = repairerStudioData.city;
  bookingData.consignee.countryCode = repairerStudioData.countryCode;
  bookingData.consignee.email = repairerStudioData.email;
  bookingData.consignee.mobileNumber = repairerStudioData.phoneNumber;
  bookingData.consignee.postalCode = repairerStudioData.postalCode;
  bookingData.consignee.street = repairerStudioData.addressLine;

  bookingData.consignor = {} as CustomerModel;
  bookingData.consignor.name = orderData.firstName + orderData.lastName;
  bookingData.consignor.city = orderData.city;
  bookingData.consignor.countryCode = orderData.country;
  bookingData.consignor.email = orderData.email;
  // bookingData.consignor.mobileNumber = orderData.phoneNumber;
  bookingData.consignor.postalCode = orderData.postalCode;
  bookingData.consignor.street = orderData.address;

  bookingData.package = {} as PostnordPackageModel;
  bookingData.package.heightInCmt = 30;
  bookingData.package.widthInCmt = 20;
  bookingData.package.lengthInCmt = 50;
  bookingData.package.grossWeightInKgm = 1;
  bookingData.package.orderId = OrderID;

  bookingData.pickupPoint = {
    customerId:orderData.shipmentDetails.pickupDetail.customerID,
    
    name:orderData.shipmentDetails.pickupDetail.name,
    physicalAddress:{
      street:orderData.shipmentDetails.pickupDetail.addressLine1,
      street2:orderData.shipmentDetails.pickupDetail.addressLine2,
      postalCode:orderData.shipmentDetails.pickupDetail.postalCode,
      city:orderData.shipmentDetails.pickupDetail.city,
      countryCode:orderData.shipmentDetails.pickupDetail.countryCode
    }

  };
  bookingData.product = {
    name : orderData.shipmentDetails.pickupDetail.productName,
    code: orderData.shipmentDetails.pickupDetail.productCode,
  };

  return bookingData;
};
