export const getSplitCharacters = (inputString: string) => {
  const charArray = [];
  let currentWord = '';

  for (const char of inputString) {
    if (/\s/.test(char)) {
      if (currentWord.length > 0) {
        charArray.push(currentWord);
        currentWord = '';
      }
      charArray.push(' '); // Add space as separate element
    } else {
      currentWord += char;
    }
  }

  // Add the last word (if any)
  if (currentWord.length > 0) {
    charArray.push(currentWord);
  }

  return charArray;
};
