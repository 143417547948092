import React from 'react';
import { Link } from 'react-router-dom';
import './layout.scss';
import { Shield } from '@mui/icons-material';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="layout">
      <header className="header">
        <div className="container">
          <Link to="/" className="logo">
            <img src="tavare.jpeg" alt="TAVARE logo" height="60px" />
          </Link>
          <h1 className="welcome-text">Velkommen som pilotkunde for TAVARE!</h1>
        </div>
      </header>

      <main className="main">
        {children}
      </main>

      <footer className="footer">
        <div className="container">
          <p>
          I januar lanserer vi appen vår! Men før det er du en av de første utvalgte som får tilgang til de gode prisene og leveringstjenesten vår.
          </p>
          <p>Mer kommer 🌍</p>
          <p>
            Eventuelle spørsmål kan sendes til{' '}
            <a href="mailto:ole@repairable.no">
              ole@repairable.no
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Layout;