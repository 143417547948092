import { messages } from '../../Messages';
import { Text } from '../../components/UI/Text';
import { RepairDetailData } from '../../types/order/order.model';

interface Props {
  brandDetails: any;
  orderData: RepairDetailData;
}

export const OrderSummaryContactInfo: React.FC<Props> = ({ brandDetails, orderData }) => {
  return (
    <>
      <Text
        text={messages.repairBookingForm.summary.contactInfo}
        className={`${brandDetails.brandName}-description-bold small-screen-font-size`}
      />
      <Text
        text={`${orderData.firstName} ${orderData.lastName}`}
        className={`${brandDetails.brandName}-description-small small-screen-font-size`}
      />
      <Text
        text={`${orderData.address}`}
        className={`${brandDetails.brandName}-description-small small-screen-font-size`}
      />
      <Text
        text={`${orderData.phoneNumber}`}
        className={`${brandDetails.brandName}-description-small small-screen-font-size`}
      />
    </>
  );
};
