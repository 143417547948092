import { createSlice } from '@reduxjs/toolkit';
import { productDataState } from './categoryDataTypes';

const initialState: productDataState = {
  selectedCategory: false,
};

const ProductDataSlice = createSlice({
  name: 'productData',
  initialState,
  reducers: {
    setSelectedProductCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
  },
});

export const productDataReducer = ProductDataSlice.reducer;
