import { createSlice } from '@reduxjs/toolkit';
import { discountCodeDataState } from './discountCodeDataTypes';

const initialState: discountCodeDataState = {
  discountCode: [],
};

const discountCodeDataSlice = createSlice({
  name: 'discountCodeData',
  initialState,
  reducers: {
    setSelectedDiscountCode: (state, action) => {
      state.discountCode = action.payload;
    },
  },
});

export const discountCodeDataReducer = discountCodeDataSlice.reducer;
