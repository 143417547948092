export const languages = [
  {
    label: 'English',
    value: 'en'
  },
  {
    label: 'Finnish',
    value: 'fi'
  },
  {
    label: 'German',
    value: 'de'
  },
  {
    label: "Norwegian",
    value: 'no'
  },
  {
    label: "Swedish",
    value: 'sv'
  }
]