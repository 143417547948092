import React from 'react';
import { Home } from '../home';
import { DynamicHome } from '../dynamic-home';
import { GenericHome } from '../generic-home';
import { TemplateTypeContext } from '../..';
import { useLocation, useNavigate } from 'react-router-dom';

export const SelectHome: React.FC = () => {
  let templateDetails = React.useContext(TemplateTypeContext);
  const navigate = useNavigate();
  const location = useLocation();

  const verifyIntegration = React.useCallback(() => {
    try {
      const queryParams = new URLSearchParams(location.search);
      const integrationID = queryParams.get('integration_id');
      const integrationName = queryParams.get('integration_name');
      // const integrationID = '12345';
      // const integrationName = 'tings';
      if (integrationID) {
        navigate('/repair-booking', {
          state: { serviceType: 'repair', integrationID: integrationID, integrationName: integrationName },
        });
      }
    } catch {
      console.log('No Query Params');
    }
  }, []);

  React.useEffect(() => {
    verifyIntegration();
  });

  switch (templateDetails) {
    case 'Design1':
      return <Home />;
    case 'Design2':
      return <DynamicHome />;
    case 'Design3':
      return <GenericHome />;
    default:
      return <GenericHome />;
  }

  // let brandConfiguration = React.useContext(BrandConfigurationContext);
  // // const brandConfiguration = {};
  // brandConfiguration = {} as BrandConfigurationModel;

  // const noBrandConfig = Object.keys(brandConfiguration).length === 0;

  // console.log('No Brand Config:', noBrandConfig);

  // if (noBrandConfig) {
  //   return <GenericHome />;
  // }

  // const shopifyIntegrated: string = brandConfiguration.shopifyIntegrated.toString();

  // return shopifyIntegrated === 'true' ? <DynamicHome /> : <Home />;
};
