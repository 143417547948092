import React from 'react';
import { DryCleanOrders } from '../../types/order/order.model';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getDryCleanOrderDetails, updateDryCleanOrderPaymentStatus } from '../../utils/api';
import { ApiKeyContext } from '../..';
import { PageLoader } from '../../components/UI/PageLoader';

import './DryCleanBooking.scss';

export const DryCleanOrderSummary: React.FC = () => {
  let [loading, setLoading] = React.useState<boolean>(true);
  let [orderData, setOrderData] = React.useState<DryCleanOrders>({} as DryCleanOrders);
  let [error, setError] = React.useState<string | null>(null);

  const [searchParams] = useSearchParams();
  let apiKey = React.useContext(ApiKeyContext);
  const navigate = useNavigate();

  const updateOrderPayment = React.useCallback(async (OrderID: number) => {
    await updateDryCleanOrderPaymentStatus(apiKey, OrderID)
      .then(async () => {
        await getDryCleanOrderDetails(apiKey, OrderID).then(async (data) => {
          setOrderData(data);
        });
      })
      .catch((e) => {
        console.log('Error:', e);
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    const orderID = Number(searchParams.get('orderId'));
    if (orderID) {
      updateOrderPayment(orderID);
    } else {
      setLoading(false);
      setError('error');
    }
  }, [searchParams, updateOrderPayment]);

  const handleNewBooking = () => {
    navigate('/dryclean-booking');
  };

  return loading ? (
    <PageLoader content="Laster inn ordreoversikt. Vennligst vent og ikke avbryt" />
  ) : (
    <div className="booking-container">
      <div className="booking-form">
        <h2>Ordreoversikt</h2>
        <div className="booking-summary">

          {orderData.products?.map((product, index) => (
            <p key={index}>
              <strong>{product.name}: </strong> {product.count} x {product.cost} NOK = {product.count * product.cost} NOK
            </p>
          ))}
          
          <p>
            <strong>Totalkostnad:</strong> {orderData?.totalCost} NOK
          </p>
          <p>
            <strong>Navn:</strong> {orderData.name}
          </p>
          <p>
            <strong>Email:</strong> {orderData.email}
          </p>
          <p>
            <strong>Telefon:</strong> {orderData.phone}
          </p>
          <button onClick={handleNewBooking} className="booking-button">
          Lag ny bestilling
          </button>
        </div>
      </div>
    </div>
  );
};
