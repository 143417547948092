import axios from 'axios';

let ip = process.env.REACT_APP_ORDER_BOOKING_API_URL;

// const headers: any = {
//   'Access-Control-Allow-Credentials': true,
//   Authorization: 'c60aa6f84c393ef4ea99c1b7de41c0d799344e79d9ad2267d7e37282ce8379a8',
// };

export const rootPath = ip + '';

// axios.defaults.withCredentials = true;

const orderBookingApi = axios.create({
  baseURL: rootPath,
  //headers: headers,
});

// Add a response interceptor
orderBookingApi.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    if (error && error.response && error.response.status === 401) {
      console.log(error);
    }
    return Promise.reject(error);
  },
);

export default orderBookingApi;
