import React from 'react';
import './home.scss';
// import { Logo } from '../../components/UI/Logo';
import { Button } from '../../components/UI/Button';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../components/UI/Text';
import { messages } from '../../Messages';
import { BrandContext } from '../../App';
import { ApiKeyContext, BrandConfigurationContext } from '../..';
import { getBlobsInContainer } from '../../blobConfig';
import { PageLoader } from '../../components/UI/PageLoader';
import { motion } from 'framer-motion';
import { getSplitCharacters } from './utils';

const Logo = React.lazy(() => import('../../components/UI/Logo/index'));

export const Home: React.FC = () => {
  const animationVariants = {
    hidden: { opacity: 0 },
    reveal: { opacity: 1 },
  };

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [logoSource, setLogoSource] = React.useState('');
  const onRepairClick = () => {
    navigate('/repair-booking');
  };
  const brandDetails = React.useContext(BrandContext);
  const brandConfiguration = React.useContext(BrandConfigurationContext);

  const title = getSplitCharacters(brandConfiguration.homePageHeader);
  const slogan = getSplitCharacters(brandConfiguration.homePageDescription);

  let apikey = React.useContext(ApiKeyContext);
  // let apikey = 'd60aa6f84c393ef4ea99c1b7de41c0d799344e79d9ad2267d7e37282ce8379a8';

  const getBrandLogo = React.useCallback(
    async (brandName: string) => {
      await getBlobsInContainer(apikey, brandName, 'logo.svg')
        .then((resp) => {
          setLoading(true);
          setLogoSource(resp);
        })
        .catch((error) => {
          console.log("Couldn't Fetch Logo");
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [apikey],
  );

  const handleLogoClick = () => {
    window.open('https://brgn.com/', '_blank');
  };

  React.useEffect(() => {
    setLoading(true);
    getBrandLogo(brandDetails?.brandName);
    setLoading(false);
  });

  return (
    <div className="home-page">
      {brandConfiguration && brandDetails && (
        <>
          <div className="home-page-left container-item">
            <div className="logo-container">
              {logoSource && (
                <React.Suspense fallback="">
                  <Logo imageSource={logoSource} altText="image" className="brand-logo" onClick={handleLogoClick} />
                </React.Suspense>
              )}
            </div>

            <div className="home-description">
              <motion.div
                className="motion-text"
                initial="hidden"
                whileInView="reveal"
                transition={{ staggerChildren: 0.03 }}
              >
                {title.map((word, index) => (
                  <motion.span key={index} transition={{ duration: 0.5 }} variants={animationVariants}>
                    <Text text={word === ' ' ? '\u00A0' : word} className={`${brandDetails.brandName}-heading title`} />
                  </motion.span>
                ))}
              </motion.div>

              <motion.div
                className="motion-text"
                initial="hidden"
                whileInView="reveal"
                transition={{ staggerChildren: 0.03 }}
              >
                {slogan.map((word, index) => (
                  <motion.span key={index} transition={{ duration: 0.35 }} variants={animationVariants}>
                    <Text
                      text={word === ' ' ? '\u00A0' : word}
                      className={`${brandDetails.brandName}-description slogan`}
                    />
                  </motion.span>
                ))}
              </motion.div>
            </div>

            <div className="action-button">
              <Button
                variant="outlined"
                className={`${brandDetails.brandName}-font order-repair`}
                buttonText={messages.home.button}
                onClick={onRepairClick}
                disabled={!brandDetails}
              />
            </div>
          </div>
          <div className="home-page-right container-item">
            <div className="background-image-container"></div>
          </div>
        </>
      )}
      {loading && <PageLoader className="overlay" />}
    </div>
  );
};
