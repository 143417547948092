import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ApiKeyContext } from '../..';
import { DryCleanOrders } from '../../types/order/order.model';
import { getDryCleanOrderDetails } from '../../utils/api';
import { PageLoader } from '../../components/UI/PageLoader';
import ErrorIcon from '@mui/icons-material/Error';
import { Text } from '../../components/UI/Text';
import { DryCleanCheckoutCardInfo } from './DryCleanCheckoutCardInfo';
import './DryCleanBooking.scss';

export const DryCleanCheckout: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string>('');
  const [searchParams] = useSearchParams();
  let apiKey = React.useContext(ApiKeyContext);
  const [orderData, setOrderData] = React.useState<DryCleanOrders>({} as DryCleanOrders);

  const fetchOrderDetails = React.useCallback(async (ID: number) => {
    await getDryCleanOrderDetails(apiKey, ID)
      .then(async (data) => {
        console.log('order data :: ', data);
        setOrderData(data);
      })
      .catch((e) => {
        console.log('Info Error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    const ID = Number(searchParams.get('orderId'));
    if (ID) {
      fetchOrderDetails(ID);
    } else {
      setLoading(false);
      setError('error');
    }
  }, [searchParams]);

  return loading ? (
    <PageLoader content="Laster inn betalingssiden. Vennligst vent og ikke avbryt" />
  ) : (
    <>
      {error ? (
        <div className="error-info">
          <ErrorIcon />
          <Text text="Some error occured." className={`BRGN-description-bold`} />
        </div>
      ) : (
        <>
          <div className="checkout-container">
            <div className="left-container">
              <Text text="Ordredetaljer" className={`BRGN-description-bold bold`} />

              <div className="cost-summary">
                <div className="cost">
                  <Text text="Order ID" className={`BRGN-description`} />
                  <Text text={orderData?.orderID.toString()} className={`BR GN-description`} />
                </div>

               
                <div className="cost">
                  <Text text="Sub Total" className={`BRGN-description`} />
                  <Text text={orderData?.totalCost.toString() + " NOK"} className={`BRG N-description`} />
                </div>

                <div className="cost">
                  <Text text="Logistics Cost" className={`BRGN-description`} />
                  <Text text="25 NOK" className={`BRG N-description`} />
                </div>

                <div className="cost">
                  <Text text="Service Fee" className={`BRGN-description`} />
                  <Text text="10 NOK" className={`BRG N-description`} />
                </div>

                <div className="cost">
                  <Text text="Total Cost" className={`BRGN-description-bold`} />
                  <Text text={(orderData.totalCost + 25 + 10).toString() + " NOK"} className={`BRGN-description-bold`} />
                </div>


              </div>
            </div>

            <div className="right-container">
              <DryCleanCheckoutCardInfo orderData={orderData} apiKey={apiKey} />
            </div>
          </div>
        </>
      )}
    </>
  );
};
